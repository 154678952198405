import "./App.css";

import Footer from "./component/Footer";

import Header from "./component/Header";
import { Routes, Route } from "react-router-dom";

import Welcome from "./views/Welcome";
import Mentions from "./views/Mentions";
import Politique from "./views/Politique";

export default function App() {

 
    return (
      <body id="top" className="flex flex-col min-h-screen">
        <Header />

        <main className="flex-grow">
          <Routes>
            <Route path="/*" element={<Welcome />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="/politique" element={<Politique />} />
          </Routes>
        </main>
        <Footer />
      </body>
    );

}
